
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  auth: false,
  layout: 'property-manager',
  data(){
    return{
      isSliderEnabled: true,
      valid: false,
      loginDialog: false,
      showSuccessContactPopup1: false,
      subdomainName: null,
      managerConfig: null,
      bannerText: '',
      disableOwner: false,
      disableTenant: false,
      messageRules: [
        v => !!v || this.$t('error.Message is required'),
        v =>
          (v && v.length >= 5) ||
          this.$t('error.Message must be equal or greater than 5 characters'),
      ],
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
       obj:{
        "name": null,
        "email": null,
        "message": null,
        subdomainName:this.subdomainName
      },
    }
  },
  computed: {
    ...mapState('taxonomy', ['taxonomy']),
    ...mapState('config/site', [
      'isBlur',
      'siteConfig',
      'isScheduleShowOpen',
      'isLogInDialogShowOpen',
    ]),
  },
  created() {
    this.resetHeaderState()
    this.checkUser()
  },
  watch:{
    managerConfig() {
       this.bannerText =
         this.managerConfig[`bannerText${this.$vuetify.lang.current}`]
     },
  },
  mounted() {
    const host = window.location.host
    const parts = host.split('.')
    console.log(parts)
    this.subdomainName = parts[0]
    if (this.subdomainName) {
      this.getSubDomainConfig()
    }
  },
  // destroyed(){
  //   console.log('destroyed')
  //   this.$vuetify.theme.themes.light.primary = this.siteConfig.primaryColor
  // },
  methods: {
    ...mapMutations('config/site', ['setLogInDialogShow','setErrorSnackbar']),
    ...mapActions('config/site', ['setTypeOfLoginwant']),
    ...mapActions('config/menu', ['resetHeaderState']),
    ...mapActions('subdomain', ['getConfig','sendPersonalWebsiteContact']),
    disableSlider() {
      console.log('Slider disabled');
      this.isSliderEnabled = false;
    },
    enableSlider() {
      console.log('Slider enabled');
      this.isSliderEnabled = true;
    },
    validate() {
      return this.$refs.propertyForm.validate()
    },
    sendForm(){
      console.log('this.validate()',this.$refs.propertyForm)
      if(this.validate()){
        if (process.client) {
          this.$nuxt.$loading.start()
        }
        this.obj.subdomainName=this.subdomainName
        this.sendPersonalWebsiteContact(this.obj).then(data=>{
            this.setErrorSnackbar({
              title: this.$t('Message Sent!'),
              msg: this.$t(''),
              color: this.$vuetify.theme.themes.light.success,
            })
            if (process.client) {
              this.$nuxt.$loading.finish()
            }
            this.$refs.propertyForm.reset()
            this.showSuccessContactPopup1=true
        }).catch(err=>{
          this.setErrorSnackbar({
              title: this.$t('Unable to send'),
              msg: err,
              color: this.$vuetify.theme.themes.light.error,
          })
          if (process.client) {
            this.$nuxt.$loading.finish()
          }
          this.$refs.propertyForm.reset()
        })
      }
    },
    checkUser() {
      console.log(this.$auth.user)
      if (this.$auth.loggedIn) {
        if (this.$auth.user.role === 'Tenant') {
          this.disableOwner = true
        }
        if (this.$auth.user.role === 'Owner') {
          this.disableTenant = true
        }
      }
    },
    async getSubDomainConfig() {
      //   if (process.client) {
      //   this.$nuxt.$loading.start()
      // }
      this.getConfig({ subdomainName: this.subdomainName }).then(res=>{
       
        this.managerConfig = res.data
       
        console.log("this.managerConfig<<<<<<<<<<",this.managerConfig)
        if (this.managerConfig) {
          this.$vuetify.theme.themes.light.primary =
            this.managerConfig.primaryColor
          // console.log(this.$vuetify)
          if (process.client) {
            this.$nuxt.$loading.finish()
          }
        }
      })
     
    },
    checkLogin(typeOfLogin) {
      console.log('checkLogin', typeOfLogin)
      if (this.$auth.loggedIn) {
        this.$router.push(this.localePath(
          `/dashboard/${this.$auth.user.role.toLowerCase()}/main`)
        )
      } else {
        this.setTypeOfLoginwant({ typeOfLogin }).then(data => {
          console.log(data)
          this.setLogInDialogShow(true)
        })
      }
    },
  },
}
