
export default {
  props: {
    images: {
      type: Array,
      default: () => [require('~/assets/img/home/agents-h700.jpg')],
    },
    height: {
      type: [Number, String],
      default: 443,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    roundborder: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: true,
    },
    continuous: {
      type: Boolean,
      default: true,
    },
    cycle: {
      type: Boolean,
      default: true,
    },
    homeSlider: {
      type: String,
      default: 'home-slider',
    },
  },
  data: () => ({
    model: 0,
    colors: ['secondary', 'yellow darken-2', 'red', 'orange'],
  }),
}
